<template>
  <div>
    <!--begin::Dashboard-->
   <div class="container">

        <div class="row">
            <div class="col col-xl-6 col-md-6 col-sm-12 col-xs-12 mt-2">
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">

                        <div class="d-flex align-items-center mt-4">
                            <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                <div class="symbol-label" style="background-image:url('../media/users/blank.png')"></div>
                                <i class="symbol-badge bg-success"></i>
                            </div>
                            <div>
                                <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{currentUser.nombre}}</a>
                                <div class="text-muted">{{currentUser.institucion}}</div>
                                <div class="mt-2">
                                    <!-- <a href="#" class="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Mi perfil</a> -->
                                    <!-- <a href="#" class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1">Follow</a> -->
                                </div>
                            </div>
                            
                        </div>

                      <!--   <div class="card-spacer bg-white card-rounded flex-grow-1">
                            <div class="row m-0">
                                <div class="col px-8 py-6 mr-8">
                                    <div class="font-size-sm text-muted font-weight-bold"> Average Sale </div>
                                    <div class="font-size-h4 font-weight-bolder">$650</div>
                                </div>
                                <div class="col px-8 py-6">
                                    <div class="font-size-sm text-muted font-weight-bold"> Commission </div>
                                    <div class="font-size-h4 font-weight-bolder">$233,600</div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col px-8 py-6 mr-8">
                                    <div class="font-size-sm text-muted font-weight-bold"> Annual Taxes </div>
                                    <div class="font-size-h4 font-weight-bolder">$29,004</div>
                                </div>
                                <div class="col px-8 py-6">
                                <div class="font-size-sm text-muted font-weight-bold"> Annual Income </div>
                                <div class="font-size-h4 font-weight-bolder">$1,480,00</div></div></div></div> -->

                        <div class="py-9">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Correo:</span>
                                <a href="#" class="text-muted text-hover-primary">{{currentUser.correo}}</a>
                            </div>
                            <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Teléfono:</span>
                                <span class="text-muted">+50234254578</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Dirección:</span>
                                <span class="text-muted">Melbourne</span>
                            </div>
                             <div class="d-flex align-items-center justify-content-between">
                                <span class="font-weight-bold mr-2">Institución:</span>
                                <a href="#" class="text-muted text-hover-primary">SEGEPLAN</a>
                            </div> -->
                        </div>
                        <v-list-item-content class="pl-0 mt-4">
                            <!--  <div class="text-overline mb-4">
                                OVERLINE
                              </div> -->
                              <v-list-item-title class="text-h5 mb-1">
                                  <!-- <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                                  <v-divider></v-divider>
                                  <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">Proyectos</small> </p> -->
                                  
                                  <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                                  <v-btn text outlined to="/perfil">Mi perfil</v-btn>
                              </v-list-item-title>
                              <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                            </v-list-item-content>

                    </div>
                    <!--end:: card-body pt-4 -->
                </div>
                <!--end::card card-custom card-stretch-->
            </div>
            <!-- end:: flex-row-auto w-->

             <div class="col col-xl-6 col-md-6 col-sm-12 col-xs-12 mt-2" v-if="currentRoles.includes('SCO$INSTCNS')">
              <template class="d-none">
                <v-card
                  class="mx-auto"
                  outlined
                  height="332"
                  dark
                  style="background: rgb(20,136,194); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(20,136,194,1) 0%, rgba(20,117,166,1) 68%); "
                >
                  <v-list-item three-line>
                    <!-- <v-list-item-avatar
                      tile
                      size="150"
                      color="grey"
                    ></v-list-item-avatar> -->

                    <v-list-item-content class="pl-4 mt-4">
                    <!--  <div class="text-overline mb-4">
                        OVERLINE
                      </div> -->
                      <v-list-item-title class="text-h5 mb-1">
                          <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                          <v-divider></v-divider>
                          <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> {{cantidadCns}} <small class="text-h5">Proyectos</small> </p>
                          
                          <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                          <v-btn text outlined to="/proyectos/cns/externo/registro-informacion" class="mr-2">Cartera de proyectos</v-btn>

                          <v-btn text outlined to="/proyectos/cns/externo/vencimiento-proximo">Próximos a vencer</v-btn>
                      </v-list-item-title>
                      <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                    </v-list-item-content>

                  
                  </v-list-item>

                    <!-- <v-card-actions>
                      <v-btn
                        outlined
                        rounded
                        text
                      >
                        Button
                      </v-btn>
                    </v-card-actions> -->
                </v-card>
              </template>
             </div>

             <div class="col-md-12 col-sm-12 col-xs-12 mt-4" v-if="currentRoles.includes('SCO$INSTCSSD') || currentRoles.includes('SCO$INSTCSSC')">

              <template>
              <v-card
                class="mx-auto"
                height="280"
                outlined  
                dark
                style="background: rgb(116,215,205); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(116,215,205,1) 0%, rgba(40,182,162,1) 68%);"
              >
                <v-list-item three-line>
                  <!--  <v-list-item-avatar
                    tile
                    size="150"
                    color="grey"
                  ></v-list-item-avatar> -->

                  <v-list-item-content class="pl-4 mt-4">
                  <!--  <div class="text-overline mb-4">
                      OVERLINE
                    </div> -->
                    <v-list-item-title class="text-h5 mb-1">
                        <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                        <v-divider></v-divider>
                        <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">Proyectos</small> </p>
                        
                        <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                        <v-btn text outlined  to="/proyectos/css/externo/negociacion-directa" v-if="currentRoles.includes('SCO$INSTCSSD')">Negociación directa</v-btn>
                        <v-btn text outlined  class="ml-2" to="/proyectos/css/externo/negociacion-comixta" v-if="currentRoles.includes('SCO$INSTCSSC')">Negociación comixta</v-btn>
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                  </v-list-item-content>

                
                </v-list-item>

                  <!-- <v-card-actions>
                    <v-btn
                      outlined
                      rounded
                      text
                    >
                      Button
                    </v-btn>
                  </v-card-actions> -->
              </v-card>
            </template>


            <!--   <v-card
                color="#44ab96"
                dark
                style="height: 210px;"
              >
                <div class="card-body d-flex align-items-center">
                        <div><h3 class="text-white font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                          <p class="text-h6 text-justify text-white">Negociaciones</p>
                          <v-btn text outlined to="/proyectos/css/dad/negociacion-directa">Directa</v-btn>
                          <v-btn text outlined to="/proyectos/css/dad/negociacion-comixta" class="ml-2">Comixtas</v-btn>
                        </div>
                    </div>
              </v-card>
        -->
            </div>

            <div class="col-md-6 col-sm-6 col-xs-12 mt-2" v-if="currentRoles.includes('SCO$INSTALIANZAS')">
                

                
                <div class="card card-custom gutter-b d-none"  style="height: 332px; background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                    <div class="card-body d-flex align-items-center">
                        <div>
                            <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Instrumentos de alianzas </h3>
                            <!-- <p class="text-h6 text-justify text-dark-50">Estrategias registradas del cooperante</p> -->
                            <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/alianzas/registro">Cartera de instrumentos</v-btn>
                        </div>
                    </div>
                </div>

                <template class="d-none">
                    <v-card
                        class="mx-auto"
                        outlined
                        height="330"
                        dark
                        style="background: rgb(255,182,150); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(255, 142, 150,1) 0%, rgba(255, 142, 150,1) 68%); "
                    >
                        <v-list-item three-line>
                        <!-- <v-list-item-avatar
                            tile
                            size="150"
                            color="grey"
                        ></v-list-item-avatar> -->

                        <v-list-item-content class="pl-4 mt-4">
                        <!--  <div class="text-overline mb-4">
                            OVERLINE
                            </div> -->
                            <v-list-item-title class="text-h5 mb-1">
                                <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Instrumentos de alianzas </h3>
                                <v-divider></v-divider>
                                <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">instrumentos</small> </p>
                                
                                <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                                <v-btn text outlined to="/alianzas/registro">Cartera de instrumentos</v-btn>
                            </v-list-item-title>
                            <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                        </v-list-item-content>

                        
                        </v-list-item>

                        <!-- <v-card-actions>
                            <v-btn
                            outlined
                            rounded
                            text
                            >
                            Button
                            </v-btn>
                        </v-card-actions> -->
                    </v-card>
                    </template>
                

            </div>

            <div class="col col-xl-8 col-md-12 col-sm-12 col-xs-12 col-12">


    <div class="row">

        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
            <v-card color="#1c5374" dark style="height: 250px; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url('/media/logos/cns.svg');">
                <div class="card-body d-flex align-items-center">
                    <div><h3 class="text-white font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                      <p class="text-h6 text-justify text-white">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                      <v-btn text outlined to="/proyectos/cns/administracion">Administrar proyectos</v-btn>
                    </div>
                </div>
            </v-card>
        </div> -->

        
      

      <div class="col-xl-6 col-md-6 col-sm-12 col-xs-12 col-12 mt-2">
        <v-card
         class="d-none"
        >
          <v-card-title
            style="background-color: #1c5374"
            color="#1c5374"
            class="white--text align-end"
            height="210px"
            elevation="0"
          >
            <div><h3 class="text-white font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                <p class="text-h6 text-justify text-white">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                 <v-btn dark text outlined to="proyectos/cns/externo/registro-informacion" class="white-text">Ver todos los proyectos</v-btn> 
            </div>
          </v-card-title>

          <!-- <v-card-subtitle class="pb-0">
            Number 10
          </v-card-subtitle> -->

          <v-card-text color="#fff" class="text--primary mt-4">

            <v-list two-line>
              <v-list-item-group
                active-class="pink--text"
                multiple
              >
                <template v-for="(item, index) in proyectos">
                  <v-list-item :key="item.codigo">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.codigo"></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.headline"
                        ></v-list-item-subtitle>

                        <v-list-item-subtitle v-text="item.nombreProyecto"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.estadoProyecto"></v-list-item-action-text>

                        <!-- <v-icon
                          v-if="!active"
                          color="grey lighten-1"
                        >
                          mdi-star-outline
                        </v-icon>

                        <v-icon
                          v-else
                          color="yellow darken-3"
                        >
                          mdi-star
                        </v-icon> -->
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < proyectos.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>

            <!-- <div>Whitehaven Beach</div>

            <div>Whitsunday Island, Whitsunday Islands</div> -->
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Ver todos los proyectos</v-btn> -->
          </v-card-actions>
        </v-card>

        
      </div>

      <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 col-12 mt-2 d-none">
       
          <!-- <div class="col-md-12 col-sm-12 col-xs-12 mt-4">
            <v-card
              color="#44ab96"
              dark
              style="height: 310px;"
            >
              <div class="card-body d-flex align-items-center">
                      <div><h3 class="text-white font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                        <p class="text-h6 text-justify text-white">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                        <v-btn text outlined to="/proyectos/cns/administracion">Directa</v-btn>
                        <v-btn text outlined to="/proyectos/cns/administracion" class="ml-2">Comixtas</v-btn>
                      </div>
                  </div>
            </v-card>
      
          </div> -->

          <template>
            <v-card
              class="mx-auto"
              height="280"
              dark
              style="background: rgb(116,215,205); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(116,215,205,1) 0%, rgba(40,182,162,1) 68%);"
            >
              <v-list-item three-line>
                <!--  <v-list-item-avatar
                  tile
                  size="150"
                  color="grey"
                ></v-list-item-avatar> -->

                <v-list-item-content class="pl-4 mt-4">
                 <!--  <div class="text-overline mb-4">
                    OVERLINE
                  </div> -->
                  <v-list-item-title class="text-h5 mb-1">
                      <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                      <v-divider></v-divider>
                      <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">Proyectos</small> </p>
                      
                      <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                      <v-btn text outlined  to="/proyectos/css/externo/negociacion-directa">Negociación directa</v-btn>
                      <v-btn text outlined  class="ml-2" to="/proyectos/css/externo/negociacion-comixta">Negociación comixta</v-btn>
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                </v-list-item-content>

               
              </v-list-item>

                <!-- <v-card-actions>
                  <v-btn
                    outlined
                    rounded
                    text
                  >
                    Button
                  </v-btn>
                </v-card-actions> -->
            </v-card>
          </template>

      </div>
          <!-- <div class="col-md-12 col-sm-12 col-xs-12 mt-4">
            <div class="card card-custom gutter-b"  style="height: 150px; background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                <div class="card-body d-flex align-items-center">
                    <div>
                        <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Cooperación <br>Descentralizada </h3>
                        <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cooperacion-descentralizada/externo/registro">Administrar proyectos</v-btn>
                    </div>
                </div>
            </div>
        </div> -->

       <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 col-12 mt-2 d-none">
          <template>
            <v-card
              class="mx-auto"
              dark
              height="280"
              style="background: rgb(123,159,181); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(123,159,181,1) 0%, rgba(95,118,133,1) 68%);"
            >
              <v-list-item three-line>
                <!--  <v-list-item-avatar
                  tile
                  size="150"
                  color="grey"
                ></v-list-item-avatar> -->

                <v-list-item-content class="pl-4 mt-4">
                 <!--  <div class="text-overline mb-4">
                    OVERLINE
                  </div> -->
                  <v-list-item-title class="text-h5 mb-1">
                      <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Descentralizada </h3>
                      <v-divider></v-divider>
                      <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">Proyectos</small> </p>
                      <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                      <v-btn text outlined to="/proyectos/cooperacion-descentralizada/externo/registro">Cartera de proyectos</v-btn>
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                </v-list-item-content>

               
              </v-list-item>

                <!-- <v-card-actions>
                  <v-btn
                    outlined
                    rounded
                    text
                  >
                    Button
                  </v-btn>
                </v-card-actions> -->
            </v-card>
          </template>
      

        
      </div>


      


      

        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card-custom gutter-b" style="height: 250px;">
                <div class="card-body d-flex align-items-center">
                    <div><h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                    <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                    
                    <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Administrar proyectos</v-btn></div>
                </div>
            </div>
        </div> -->


        <div class="col-xxl-12 order-0 order-xxl-2 mt-4 d-none">
            <div class="card card-custom card-stretch gutter-b">
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Últimos proyectos registrados</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Últimos proyectos CNS y CSS registrados para de la institución</span>
                    </h3>
                    <div class="card-toolbar">
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Ver CNS</v-btn>
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3 ml-2" to="/proyectos/cns/administracion">Ver CSS</v-btn>
                    </div>
                </div>
                <div class="card-body pt-0 pb-3">
                <div class="tab-content">
                    <div class="table-responsive">
                        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                            <thead>
                                <tr class="text-left">
                                    <th class="pl-7" style="min-width: 250px;"><span class="text-dark-75">Proyecto</span></th>
                                    <th style="min-width: 120px;">Tipo de proyecto</th>
                                    <th style="min-width: 120px;">Fecha de registro</th>
                                    <!-- <th style="min-width: 100px;">Acción</th> -->
                                    <!-- <th style="min-width: 100px;"></th> -->
                                    <!-- <th style="min-width: 100px;">rating</th> -->
                                    <!-- <th style="min-width: 101px;"></th> -->
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="proyecto in proyectos" :key="proyecto.id">
                                    <td class="pl-0 pt-8">
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50 symbol-light mr-4"><span class="symbol-label" style="background-color: transparent !important"><img :src="proyecto.tipoProyecto===`CNS` ? `media/logos/logo-cns.png` :  `media/logos/logo-css.png`" alt="" class="h-75 align-self-end"></span></div>
                                        <div>
                                        <a class="text-dark-75 font-weight-bold text-hover-primary mb-1 font-size-lg">{{proyecto.nombreProyecto}}</a><!-- <span class="text-muted font-weight-bold d-block">HTML, JS, ReactJS</span> --></div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{proyecto.tipoProyecto}}</span><span class="text-muted font-weight-bold">{{proyecto.tipoProyecto=="CNS" ? `Cooperación Norte-Sur` : `Cooperación Sur-Sur`}}</span>
                                    </td>
                                    
                                    <td class="text-right"><span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{proyecto.fechaCreacion}}</span><span class="text-muted font-weight-bold">In Proccess</span></td>
                                    <!--<td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">$520</span>
                                        <span class="text-muted font-weight-bold">Paid</span></td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">Intertico</span><span class="text-muted font-weight-bold">Web, UI/UX Design</span></td>
                                    <td> 
                                        <img src="media/logos/stars.png" alt="image" style="width: 5rem;"><span class="text-muted font-weight-bold d-block">Best Rated</span></td>-->
                                    <!-- td class="pr-0 text-right"><a class="btn btn-light-success font-weight-bolder font-size-sm">Ver proyecto</a></td> -->

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

        

        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card-custom gutter-b" style="height: 250px;background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                <div class="card-body d-flex align-items-center">
                    <div>
                        <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Actores locales <br> </h3>
                        <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                        
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Administrar proyectos</v-btn>
                    </div>
                </div>
            </div>
        </div> -->

        


    </div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
        <div class="col-12 col-md-12">
           <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
        </div>
    </div>

   </div>
    <div class="row d-none">
      <div class="col-xxl-4">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="col-xxl-4">
        <ListWidget9></ListWidget9>
      </div>
      <div class="col-xxl-4">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-1">
        <ListWidget1></ListWidget1>
      </div>
      <div class="col-xxl-8 order-2 order-xxl-1">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget3></ListWidget3>
      </div>
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget4></ListWidget4>
      </div>
      <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
        <ListWidget8></ListWidget8>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <StatsWidget13></StatsWidget13>
      </div>
      <div class="col-xxl-8 order-1 order-xxl-2">
        <AdvancedTableWidget3></AdvancedTableWidget3>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { OBTENER_PROYECTOS_CNS_EXTERNO, OBTENER_CANTIDAD_PROYECTOS_CNS_EXTERNO } from "@/core/services/store/proyectoscns/proyectocns.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";

export default {
  name: "DashboardInstitucion",
  components: {
    AdvancedTableWidget2,
    AdvancedTableWidget3,
    MixedWidget1,
    ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    ListWidget9,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13,
    DivisorColor
  },

  data() {
        return { 
        proyectosRegistrados:[],
        proyectos: [],
        tableLoading: false,
        cantidadCns:0
    }
  },

  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    //Obtener proyectos
    async obtenerProyectos() {
      this.proyectos = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_PROYECTOS_CNS_EXTERNO)
        .then(res => {
          if (res.status === 200) {
            this.proyectosRegistrados = this.$store.state.proyectocns.proyectos;

            this.proyectos = this.proyectosRegistrados.slice(0, 5);
          }
            //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    //Obtener proyectos
    async obtenerCantidadCNS() {
      this.cantidadCns = 0;
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_CANTIDAD_PROYECTOS_CNS_EXTERNO, {estadoId:0})
        .then(res => {
          if (res.status === 200) {
            this.cantidadCns = this.$store.state.proyectocns.cantidadCns;

            //this.can = this.proyectosRegistrados.slice(0, 5);
          }
            //console.log(this.items)
          //this.tableLoading = false;
        })
        .catch(() => {
          //this.tableLoading = false;
          this.cantidadCns =0;
        });
    },

  },

  created(){
    this.obtenerProyectos();
    if(this.currentRoles.includes('SCO$INSTCNS')){
      this.obtenerCantidadCNS();
    }
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),

    getFullName() {
      return (
        this.$store.state.auth.user.nombres +
        " " +
        this.$store.state.auth.user.apellidos
      );
    },

    firstName:{
      get(){
         return this.$store.state.auth.user.nombres.split(' ')[0];
      }
    },

    lastName:{
      get(){
         return this.$store.state.auth.user.apellidos.split(' ')[0];
      }
    },

     email:{
      get(){
         return this.$store.state.auth.user.correo;
      }
    },

    usuario:{
      get(){
         return this.$store.state.auth.user.usuario;
      }
    },

    currentRoles:{
      get(){
         return this.$store.state.auth.roles;
      }
    },
  }
};
</script>
